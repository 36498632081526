<template>
    <div>
      <Nav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill" >
            <ModelHelp v-show="modelHelpContext" @selectModel="selectModel"/>
            <div v-show="!modelHelpContext">
                <h1>Drawings</h1>
                <div class="panel panel-default">
                    <div class="panel-heading"></div>
                    <div class="row panel-body">
                        <div class="col-xs-6 form-group">
                            <label>Old Model</label>
                            <Typeahead cdi_type="pm_model" ref="oldModel" v-bind:object.sync="oldModel" />
                            <a @click="showHelp('old')" class="small cdi_link">I need help finding my Old Model</a>
                        </div>
                        <div class="col-xs-6 form-group">
                            <label>New Model</label>
                            <Typeahead cdi_type="pm_model" ref="newModel" v-bind:object.sync="newModel" />
                            <a @click="showHelp('new')" class="small cdi_link">I need help finding my New Model</a>
                        </div>
                        <div v-show="$appStore.isStaff()" class="col-md-3 col-sm-4 col-xs-6 form-group">
                            <label>Or Catalog #</label>
                            <select class="form-control" v-model="catalogNumber">
                                <option v-for="catNum in lennoxCatalog" v-bind:key="catNum.id" :value="catNum.id">{{ catNum.number }}</option>
                            </select>
                        </div>
                        <div class="col-xs-12"></div>
                        <div class="col-xs-6 form-group">
                            <label>Or Length</label>
                            <input type="text" class="form-control" v-model="length" />
                        </div>
                        <div class='col-xs-6 form-group'>
                            <label>Width</label>
                            <input type="text" class="form-control" v-model="width" />
                        </div>
                        <div class="col-xs-12">
                            <button class="btn btn-primary btn-lg btn_min_width" @click="search">Search</button>
                        </div>
                    </div>
                </div>

                <div class="well well-sm">
                    <b>Drawing Search Help </b>
                    <ul>
                        <li>Start Typing your <i>Model number</i> and options will begin to appear below</li>
                        <li>Don't use spaces or dashes (-)</li>
                        <li>Select the model from the list</li>
                    </ul>
                </div>

                <table v-show="searchResults.length" class="table">
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>File</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(file, index) in searchResults" v-bind:key="index">
                        <td>{{ file.label }}</td>
                        <td>
                            <ProtectedLink :id="`${file.cdi_file_id}`" :linkType="'link'" :text="file.link_text" :queryString="queryString" :type="'drawing'" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </main>
    </div>
</template>

<script>
import { myStore } from '@/store/My.store'
import Nav from './MyNav';
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";
import ModelHelp from "@/components/my/ModelHelp";


export default {
    name: 'Drawings',
    props: ['id'],
    components: {ProtectedLink, Typeahead, Nav, ModelHelp},
    data() {
        return {
          oldModel: {
                label: '',
                value: '',
                cdi_part_id: '',
                unit_model_id: ''
            },
            newModel: {
                label: '',
                value: '',
                cdi_part_id: '',
                unit_model_id: ''
            },
            length: '',
            width: '',
            catalogNumber: 0,
            lennoxCatalog: [],
            searchResults: [],
            modelHelpContext: '',
        }
    },
    created() {
        if (this.$appStore.isStaff()) {
            myStore.staticApi('curb/get_lennox_catalog').then( result => {
                let values = result;
                values.unshift({id: 0, number: 'None'});
                this.lennoxCatalog = values;
            });
        }
    },
    methods: {
        load() {
          // set typeaheads
          this.$refs.oldModel.$data.object = this.oldModel.value;
          this.$refs.newModel.$data.object = this.newModel.value;
        },
        search() {
            let params = {
                'params[old_model]' : this.oldModel.value,
                'params[old_model_id]' : this.oldModel.unit_model_id,
                'params[cdi_part_id_old]' : this.oldModel.cdi_part_id,
                'params[new_model]' : this.newModel.value,
                'params[new_model_id]' : this.newModel.unit_model_id,
                'params[cdi_part_id_new]' : this.newModel.cdi_part_id,
                'params[curb_id]' : this.catalogNumber,
                'params[length]' : this.length,
                'params[width]' : this.width
            }
            myStore.api('drawings/get_drawings', params).then(result => {
                if (result) {
                    this.searchResults = result;
                }else{
                    this.searchResults = [];
                }
            });
        },
      showHelp: function(context) {
        // context can be "new", "old", or "erv"
        this.modelHelpContext = context;
      },
      selectModel: function(model) {
        if (this.modelHelpContext == 'old') {
          this.oldModel.unit_model_id = model.unit_model_id;
          this.oldModel.value = model.unit_model_num;
          this.$refs.oldModel.$data.object = model.unit_model_num;
        }  else {
          this.newModel.unit_model_id = model.unit_model_id;
          this.newModel.value = model.unit_model_num;
          this.$refs.newModel.$data.object = model.unit_model_num;
        }
        this.modelHelpContext = '';
      },
    },
    computed:{
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        },
        view: function() {
          return this.modelHelpContext;
        },
    },
    watch: {
      view: function() {
        this.load();
      }
    },
}
</script>